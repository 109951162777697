<template>
    <div class="ticket" style="font-size: 8px !important">
    <div class="row" style="margin-top:2mm">
         <div v-for="i in jum" :key="i" class="col-4" style="max-height: 14mm;height:14mm; margin-top: 1mm;margin-bottom: 1.12mm;" >
           
     
                    <div style="text-overflow: ellipsis; 
            overflow: hidden; 
            white-space: nowrap;">{{ nama }}</div>
                    <vue-barcode :value=" barcode " :option="{format:'code128'}" :options="{ displayValue: false }" style="width:3cm; height: 0.8cm;"  tag="img"></vue-barcode>
            
                
         </div>
        
    
    </div>
       
    </div>
    </template>
    <script>
    
    export default {
        data() {
            return {
                //state loggedIn with localStorage
                loggedIn: localStorage.getItem('loggedIn'),
                //state token
                token: localStorage.getItem('token'),
                //state user logged In
                print_value: [],
                jumtransaksi: 0,
                jumpendapatan: 0,
                link: this.globalApiUrl,
                barcode : '',
                jum : [],
                nama : '',
            }
        },
        created() {
            if (!this.loggedIn) {
                if (!this.token) {
                    localStorage.removeItem("loggedIn");
                    localStorage.removeItem("token");
                    return this.$router.push({
                        name: 'login.index'
                    })
                } else {
                    localStorage.removeItem("loggedIn");
                    localStorage.removeItem("token");
                }
            } else {
                console.log(this.token);
                this.gests();
            this.barcode =this.$route.query.data;
            this.nama =this.$route.query.nama;
            console.log(this.nama);
    
            }
    
        },
        mounted () {
            // print();
           
        },
        methods: {
            printed() {
                print();
            },
            gests(){
                let  i=0;
                 for(i ; i< this.$route.query.total; i++){
                    this.jum.push(i);
                 }
                 console.log(this.jum);
            }
            
        },
    }
    </script>
    
    <style>
    * {
        /*font-size: 0px !important;*/
        font-family: 'arial' !important;
        line-height: normal !important;
        /* font-weight:normal; */
    }
    
    body {
         padding-top:0px !important;
        color: #000000 !important;
        background: #ffffff !important;
       position: relative;
      height: auto;
      min-height: 100% !important;
    }
    
    td,
    th,
    tr,
    table {
        border-top: 1px solid black;
        border-collapse: collapse;
    }
    
    td.description,
    th.description {
        width: 95px;
        max-width: 95px;
    }
    
    td.quantity,
    th.quantity {
        width: 26px;
        max-width: 26px;
        font-size: 9.3px !important;
        word-break: break-all;
    }
    
    td.price1,
    th.price1 {
        width: 75px;
        max-width: 75px;
        font-size: 9.3px !important;
        word-break: break-all;
    }
    
    td.price,
    th.price {
        width: 75px;
        max-width: 75px;
        font-size: 9.3px !important;
        word-break: break-all;
    }
    
    .centered {
        text-align: center;
        align-content: center;
    }
    
    .ticket {
        /* margin-left: 4px; */
        width: 11cm;
        max-width: 11cm;
        /* padding: 0.2cm; */
        padding-left: 0.3cm;
        padding-right: 0.4cm;
        /* height: 15mm; */
    }
    
    img {
        max-width: inherit;
        width: inherit;
    }
    
    @media print {
    
        .hidden-print,
        .hidden-print * {
            display: none !important;
        }
    }
    </style>